import './AddProduct.less'

import { Button, Form, Input, InputNumber, RefSelectProps } from 'antd'
import React, { useEffect, useRef, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { Product, ProductStatus, ProductType, createProduct } from '../../../../requests'
import { enumToOptions } from '../../../../utils'
import { maxTitleLengthLong, validateRequired } from '../../../../validation'
import { DatacFormItem, DatacIcon, DatacMessage, DatacModal, DatacOption, DatacSelect } from '../../../common'

interface AddProductProps {
  brandOptions: DatacOption[]
  setBrandOptions: React.Dispatch<React.SetStateAction<DatacOption[]>>
  onClose: () => void
}

export const AddProduct: React.FC<AddProductProps> = ({ brandOptions, setBrandOptions, onClose }) => {
  const intlProducts = useScopedIntl('side_by_side.products.table')
  const intlStatus = useScopedIntl('status')
  const intl = useScopedIntl('')
  const [isModalOpened, setIsModalOpened] = useState(false)
  const [formInstance] = Form.useForm()

  const [currentEnteredBrand, setCurrentEnteredBrand] = useState('')
  const [brand, setBrand] = useState('')
  const brandRef = useRef<RefSelectProps>()

  useEffect(() => {
    if (!isModalOpened) return

    setBrand('')
    setCurrentEnteredBrand('')
    formInstance.resetFields()
  }, [isModalOpened])

  const onModalClose = () => {
    setIsModalOpened(false)
    onClose()
  }

  const onSubmit = (data: Product) =>
    createProduct(data, {
      onSuccess: onModalClose,
      onProductAlreadyExists: () =>
        DatacMessage.error(intlProducts('error.exists.title'), intlProducts('error.exists.description')),
      onRequestError: code => DatacMessage.genericError(intl, code)
    })

  const onBrandSearch = (searchPhrase: string) => {
    setCurrentEnteredBrand(searchPhrase)
  }

  const setNewBrand = (brand: string) => {
    formInstance.setFieldsValue({ brand })
    setBrand(brand)
  }

  const onBrandKeyPressed = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key !== 'Enter' ||
      !currentEnteredBrand.trim() ||
      brandOptions.find(({ value }) => value === currentEnteredBrand)
    ) {
      return
    }

    setBrandOptions(currentAvailableBrands => [
      ...currentAvailableBrands,
      { value: currentEnteredBrand, label: currentEnteredBrand }
    ])
    setNewBrand(currentEnteredBrand)
    onBrandSearch('')
    setCurrentEnteredBrand('')
    brandRef.current.blur()
  }

  const onBrandSelect = (selectedBrand: string) => {
    setNewBrand(brand === selectedBrand ? '' : selectedBrand)
  }

  return (
    <>
      <Button className="add_product__button" type="primary" onClick={() => setIsModalOpened(true)} size="large">
        <DatacIcon name="plus" type="white" size="big" /> {intlProducts('add')}
      </Button>
      <Form form={formInstance} name="addProductForm" onFinish={onSubmit}>
        <DatacModal
          className="add_product__modal"
          isOpened={isModalOpened}
          onClose={onModalClose}
          onSubmit={() => formInstance.submit()}
          title={intlProducts('add')}
        >
          <div className="add_product__modal__row">
            <DatacFormItem
              name="formula"
              label={intlProducts('formula')}
              validate={validateRequired(intl('common.required'))}
              showAsterisk
            >
              <Input size="large" placeholder={intlProducts('formula.placeholder')} maxLength={maxTitleLengthLong} />
            </DatacFormItem>
            <DatacFormItem
              name="type"
              label={intlProducts('type')}
              validate={validateRequired(intl('common.required'))}
              showAsterisk
            >
              <DatacSelect
                showSearch
                options={enumToOptions(ProductType, intlStatus)}
                placeholder={intlProducts('type.placeholder')}
              />
            </DatacFormItem>
          </div>
          <div className="add_product__modal__row">
            <DatacFormItem
              name="brand"
              label={intlProducts('brand')}
              validate={validateRequired(intl('common.required'))}
              showAsterisk
            >
              <DatacSelect
                size="large"
                placeholder={intlProducts('brand.placeholder')}
                showSearch
                options={brandOptions}
                onInputKeyDown={onBrandKeyPressed}
                onSearch={onBrandSearch}
                defaultActiveFirstOption={false}
                selectRef={brandRef}
                onSelect={onBrandSelect}
              />
            </DatacFormItem>
            <DatacFormItem
              name="productName"
              label={intlProducts('product_name')}
              validate={validateRequired(intl('common.required'))}
              showAsterisk
            >
              <Input
                size="large"
                placeholder={intlProducts('product_name.placeholder')}
                maxLength={maxTitleLengthLong}
              />
            </DatacFormItem>
          </div>
          <div className="add_product__modal__row">
            <DatacFormItem
              name="category"
              label={intlProducts('category')}
              validate={validateRequired(intl('common.required'))}
              showAsterisk
            >
              <Input size="large" placeholder={intlProducts('category.placeholder')} maxLength={maxTitleLengthLong} />
            </DatacFormItem>
            <DatacFormItem name="technology" label={intlProducts('technology')}>
              <Input size="large" placeholder={intlProducts('technology.placeholder')} maxLength={maxTitleLengthLong} />
            </DatacFormItem>
          </div>
          <div className="add_product__modal__row">
            <DatacFormItem name="status" label={intlProducts('status')}>
              <DatacSelect
                showSearch
                options={enumToOptions(ProductStatus, intlStatus)}
                placeholder={intlProducts('status.placeholder')}
              />
            </DatacFormItem>
            <DatacFormItem name="shadeName" label={intlProducts('shade_name')}>
              <Input size="large" placeholder={intlProducts('shade_name.placeholder')} maxLength={maxTitleLengthLong} />
            </DatacFormItem>
          </div>
          <div className="add_product__modal__row">
            <DatacFormItem name="batch" label={intlProducts('batch')}>
              <Input size="large" placeholder={intlProducts('batch.placeholder')} maxLength={maxTitleLengthLong} />
            </DatacFormItem>
            <DatacFormItem name="devVol" label={intlProducts('dev_vol')}>
              <Input size="large" placeholder={intlProducts('dev_vol.placeholder')} maxLength={maxTitleLengthLong} />
            </DatacFormItem>
          </div>
          <div className="add_product__modal__row">
            <DatacFormItem name="mixRatio" label={intlProducts('mix_ratio')}>
              <Input size="large" placeholder={intlProducts('mix_ratio.placeholder')} maxLength={maxTitleLengthLong} />
            </DatacFormItem>
            <DatacFormItem name="quantity" label={intlProducts('quantity')}>
              <Input size="large" placeholder={intlProducts('quantity.placeholder')} maxLength={maxTitleLengthLong} />
            </DatacFormItem>
          </div>
          <div className="add_product__modal__row">
            <DatacFormItem name="processingTime" label={intlProducts('processing_time')}>
              <InputNumber size="large" placeholder={intlProducts('processing_time.placeholder')} min={0} />
            </DatacFormItem>
            <DatacFormItem name="specialInstructions" label={intlProducts('special_instructions')}>
              <Input
                size="large"
                placeholder={intlProducts('special_instructions.placeholder')}
                maxLength={maxTitleLengthLong}
              />
            </DatacFormItem>
          </div>
          <div className="add_product__modal__row">
            <DatacFormItem name="specialApplication" label={intlProducts('special_application')}>
              <Input
                size="large"
                placeholder={intlProducts('special_application.placeholder')}
                maxLength={maxTitleLengthLong}
              />
            </DatacFormItem>
          </div>
        </DatacModal>
      </Form>
    </>
  )
}
